@import "./base/font.pcss";
@import "./base/heading.pcss";
@import "./base/colors.pcss";

@import "./elements/button.pcss";
@import "./elements/link.pcss";
@import "./elements/table.pcss";

@import "./components/typography.pcss";
@import "./components/toast.pcss";
@import "./components/redesign-status.pcss";
@import "./components/date-input.pcss";
@import "./components/debug-info.pcss";
@import "./components/tags-input.pcss";
@import "./components/coverage-rule-form.pcss";
@import "./components/navbar.pcss";
@import "./components/global-search.pcss";
@import "./components/payments-list.pcss";
@import "./components/invoice-revisions.pcss";
@import "./components/list.pcss";
@import "./components/style-guide-example.pcss";
@import "./components/stepper.pcss";
@import "./components/panel.pcss";
@import "./components/badge.pcss";
@import "./components/nav-tabs.pcss";
@import "./components/layout-header.pcss";
@import "./components/profile-picture.pcss";
@import "./components/skeleton.pcss";
@import "./components/ancillary-charges-view-details.pcss";

@import "./ui/form/checkbox.pcss";
@import "./ui/form/select.pcss";
@import "./ui/form/group.pcss";
@import "./ui/datepicker.pcss";
@import "./ui/toggle.pcss";
@import "./ui/dropdown-menu.pcss";
@import "./ui/flyout-menu.pcss";
@import "./ui/icon.pcss";
@import "./ui/modal.pcss";
@import "./ui/phi.pcss";
@import "./ui/popover.pcss";
@import "./ui/searchable-select.pcss";
@import "./ui/tooltip.pcss";
@import "./ui/underline-tabs.pcss";
@import "./ui/list-group.pcss";
@import "./ui/input-group.pcss";
@import "./ui/pagination.pcss";
@import "./ui/breadcrumbs.pcss";
@import "./ui/alert.pcss";
@import "./ui/flex-grid.pcss";
@import "./ui/timeline.pcss";
@import "./ui/new-dropdown-menu.pcss";

@import "./utils/stacking.pcss";
@import "./utils/layout.pcss";
@import "./utils/stretched-link.pcss";

@import "./hacks/icons.pcss";
@import "./hacks/google-recaptcha.pcss";
@import "./hacks/patients-list.pcss";

a * {
  pointer-events: none;
}

.form-control__feedback {
  position: absolute;
  right: 0;
  margin-top: 8px;
}

.container {
  max-width: 100vw;
}

.simpler-dropdown {
  @apply inline-block;

  &__item {
    & > a > svg {
      @apply fill-current h-3 w-3 mr-2 -mb-px;
    }
  }

  & &__item > a,
  & &__item > a:hover {
    @apply text-current;
  }

  &--round {
    .button--sm {
      @apply px-1 rounded-full;
    }
  }
}

@tailwind utilities;
